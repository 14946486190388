.customModal {
  border-radius: 5px;
  min-width: 350px;
  /* min-height: 200px; */
}

.modal-header {
  margin-bottom: 1rem;
}
.modal-content{
  padding: 10px;
}
.error-icon {
  width: 40px;
  height: 40px;
  background-color: #fafafa;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-icon svg {
  color: rgb(228, 129, 0);
  width: 1.5rem;
}

.react-responsive-modal-overlay {
  background: rgb(0 0 0 / 18%);
}
