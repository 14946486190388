.stepItemsContainer {
  position: relative;
}

.stepItem {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-bottom: 120px;
}

/* Right to Left Animation */
.fadeRightToLeft-enter {
  transform: translateX(100%);
  opacity: 0;
}

.fadeRightToLeft-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 500ms ease, opacity 500ms ease;
}

.fadeRightToLeft-exit {
  transform: translateX(0);
  opacity: 1;
}

.fadeRightToLeft-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 500ms ease, opacity 500ms ease;
}

/* Left to Right Animation */
.fadeLeftToRight-enter {
  transform: translateX(-100%);
  opacity: 0;
}

.fadeLeftToRight-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 500ms ease, opacity 500ms ease;
}

.fadeLeftToRight-exit {
  transform: translateX(0);
  opacity: 1;
}

.fadeLeftToRight-exit-active {
  transform: translateX(100%);
  opacity: 0;
  transition: transform 500ms ease, opacity 500ms ease;
}

.input-styled {
  border: 1px solid #e1e1e1 !important;
  background-color: #fafafa !important;
  border-radius: 2px !important;
}

.form-group label {
  margin-bottom: 10px;
}

.form-group .form-control {
  padding: 10px;
}

.accordion-button:not(.collapsed)::after,
.accordion-button::after {
  background-image: unset !important;
}

.option-description {
  font-size: 13px !important;
  color: #a3a6a8;
  font-weight: 400;
}

.suboption-description {
  margin-top: 10px;
  font-size: 13px !important;
  color: #a3a6a8;
  font-weight: 400;
}

.hstack {
  align-items: self-start !important;
}

.thank-form p {
  font-size: 1rem;
}
