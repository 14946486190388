body {
  overflow-x: hidden;
  overflow-y: scroll !important;
}
.card:hover {
  border-color: #0c32ed;
  box-shadow: 0 0 0 1px #0c32ed;
}
.card-flex {
  flex: 0 0 100%;
}
.card-no-hover:hover {
  border: 1px solid #e1e1e1;
  box-shadow: unset;
}
.btn-primary-outline {
  color: #75797d;
  border-radius: 4px;
  padding-top: 16px;
  padding-bottom: 16px;
  border: 2px solid #75797d;
  background-color: transparent;
}
.btn-primary-outline:hover {
  color: #ffffff !important;
  background-color: #75797d;
}
.notClickable {
  cursor: default;
}
